/**
 * PageLayout is a default page wrapper defining a study page.
 * Supported props: config, ctx, currentpath
 */

import React from 'react';
import Nav from './Nav';
import Footer from './Footer';
import { makeTopLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';
import pfizerLogo from '../images/pfizer-logo.png';
import biontechLogo from '../images/biontech.png';

export default function PageLayout(props) {
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = makeTopLinks(cfg.links);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };

  return (
    <div id="page">
      <div id="layout-root">
        <header>
          <Nav toplinks={toplinks}
               sitename={cfg.sitename}
               nonTranslated={cfg.nonTranslated}
               ctx={props.ctx}
               currentpath={props.currentpath}
               localizerfn={localized} />
        </header>
        <div id="main" className="main">
          {props.children}
        </div>
        <Footer src={footdata} localizerfn={localized} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-2">
              <div id="logo-box">
                <div className="fl fl-1">
                  <img src={pfizerLogo} alt="Pfizer Logo" className="img-responsive" />
                </div>
                <div className="fl fl-2">
                  <img src={biontechLogo} alt="BioNTech Logo" className="img-responsive" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
